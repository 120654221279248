import moment from 'moment'

const formatDate = (item, field) =>
  moment(item[field]).format('DD/MM/YYYY HH:mm')

const COLS = 4
const SHOW_FUNCTION = (item) => !!item
const FORMAT_FUNCTION = (item, field) => item[field]
const BASE = { cols: COLS, show: SHOW_FUNCTION, format: FORMAT_FUNCTION }


const city = { ...BASE, label: 'Cidade', field: 'city' }
const email = { ...BASE, label: 'Email', field: 'email' }
const ip = { ...BASE, label: 'IP', field: 'ip' }
const licensePlate = { ...BASE, label: 'Placa', field: 'license_plate' }
const personType = { ...BASE, label: 'Tipo de Pessoa', field: 'typeLabel' }
const phone = { ...BASE, label: 'Telefone/Celular', field: 'phone' }
const state = { ...BASE, label: 'Estado', field: 'state' }
const whatsapp = { ...BASE, label: 'WhatsApp', field: 'whatsapp' }
const year = { ...BASE, label: 'Ano', field: 'year' }
const purchaseForecastLabel = {
  ...BASE,
  label: 'Previsão de Compra',
  field: 'purchaseForecastLabel'
}

const acceptDate = {
  ...BASE,
  label: 'Data de aceito os termos',
  field: 'created_at',
  format: formatDate
}

const cpf = {
  ...BASE,
  label: 'CPF',
  field: 'document',
  show: (item) => item.type == 1
}

const cpnj = {
  ...BASE,
  label: 'CNPJ',
  field: 'document',
  show: (item) => item.type == 2
}

const enterprise = {
  ...BASE,
  label: 'Nome da Empresa',
  field: 'enterprise',
  show: (item) => item.type == 2
}

const model = {
  ...BASE,
  label: 'Modelo',
  field: 'model',
  format: (item, field) => item[field].title
}

const name = {
  ...BASE,
  label: 'Nome Completo',
  field: 'name',
  show: (item) => item.type == 1
}

const observation = {
  ...BASE,
  label: 'Observações',
  field: 'observation',
  cols: 12
}

const sendDate = {
  ...BASE,
  label: 'Data de envio',
  field: 'created_at',
  format: formatDate
}

const unity = {
  ...BASE,
  label: 'Unidade',
  field: 'unity',
  format: (item, field) => item[field].title
}

const vehicle = {
  ...BASE,
  label: 'Veículo',
  field: 'vehicle',
  format: (item, field) => item[field].title
}

const whichVehicleOrShareOfInterest = {
  ...BASE,
  label: 'Qual veículo ou cota de interesse',
  field: 'which_vehicle_or_share_of_interest'
}

const service = {
  ...BASE,
  label: 'Serviço',
  field: 'service',
  format: (item, field) => item[field].title
}

const restrictedData = [ip, acceptDate]
const commonFields = [
  sendDate,
  personType,
  cpf,
  cpnj,
  name,
  enterprise,
  phone,
  whatsapp,
  email,
  city,
  state
]

// Colls for datatable
const created_at = {
  key: 'created_at',
  label: 'Data do envio',
  sorter: true
}

const document = {
  key: 'document',
  label: 'CPF/CNPJ',
  sorter: true
}

const colName = {
  key: 'name',
  label: 'Nome',
  sorter: true
}

const colEmail = {
  key: 'email',
  label: 'Email',
  sorter: true
}

const colService = {
  key: 'service.title',
  label: 'Serviço',
  sorter: false
}

const actions = {
  key: 'actions',
  label: 'Ações',
  sorter: false,
  _style: 'width: 12%;'
}

const commonCols = [created_at, document, colName, colEmail]

const leads = {
  models: {
    uri: 'lead-models',
    restrictedData,
    cols: [...commonCols],
    fields: [...commonFields, model, purchaseForecastLabel]
  },
  vehicles: {
    uri: 'lead-vehicles',
    restrictedData,
    cols: [...commonCols],
    fields: [...commonFields, model, vehicle, purchaseForecastLabel]
  },
  parts: {
    uri: 'lead-parts',
    restrictedData,
    cols: [...commonCols],
    fields: [...commonFields]
  },
  consortiums: {
    uri: 'lead-consortium',
    restrictedData,
    cols: [...commonCols],
    fields: [...commonFields, whichVehicleOrShareOfInterest].reduce(
      (prev, next) => [...prev, ...(next.field === 'phone' ? [next] : [next])],
      []
    )
  },
  'maintenance-plans': {
    uri: 'lead-maintenance-plans',
    restrictedData,
    cols: [...commonCols, actions],
    fields: [
      ...commonFields,
      unity,
      { ...model, format: FORMAT_FUNCTION },
      year,
      observation
    ]
  },
  'review-schedules': {
    uri: 'lead-review-schedules',
    restrictedData,
    cols: [...commonCols, { ...colService, format: FORMAT_FUNCTION }, actions],
    fields: [
      ...commonFields,
      service,
      { ...vehicle, format: FORMAT_FUNCTION },
      licensePlate
    ]
  },
  'top-drivers': {
    uri: 'lead-top-drivers',
    restrictedData,
    cols: [...commonCols, actions],
    fields: [
      sendDate,
      { ...name, show: SHOW_FUNCTION },
      email,
      phone,
      whatsapp,
      { ...enterprise, show: SHOW_FUNCTION }
    ]
  },
  'iveco-connect': {
    uri: 'lead-iveco-connect',
    restrictedData,
    cols: [...commonCols, actions],
    fields: [...commonFields, { ...vehicle, format: FORMAT_FUNCTION }]
  },
  insurances: {
    uri: 'lead-insurance',
    restrictedData,
    cols: [...commonCols, actions],
    fields: [...commonFields]
  },
  financing: {
    uri: 'lead-financing',
    restrictedData,
    cols: [...commonCols, actions],
    fields: [...commonFields]
  }
}

export default leads
