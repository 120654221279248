<template>
  <CRow>
    <Modal :modal="alertModal"></Modal>

    <CModal
      color="danger"
      :title="`Excluir ${cardTitle}`"
      :show.sync="modal"
      :centered="true"
    >
      Após excluir não será possível reverter a ação
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="() => (modal = false)"
          :disabled="removing"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="remove"
          :disabled="removing"
        >
          {{ removing ? 'Excluindo...' : 'Excluir' }}
        </button>
      </template>
    </CModal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>

      <FormCardTitle v-model="item" :title="cardTitle">
        <template #append>
          <CButton
            @click="() => (modal = true)"
            color="danger"
            class="float-right"
          >
            <CIcon name="cil-trash" class="mr-1" />
            <span>Excluir lead</span>
          </CButton>
        </template>
      </FormCardTitle>

      <FormCardItem title="Dados informativos">
        <template v-for="field in fields">
          <CCol
            col="12"
            :md="field.cols"
            :key="field.label"
            v-if="field.show(item)"
          >
            <p>
              <b>{{ field.label }}: </b>
              <br />
              {{ field.format(item, field.field) }}
            </p>
          </CCol>
        </template>
      </FormCardItem>

      <CCard class="card-restricted-data">
        <CCardHeader
          class="btn text-left d-flex align-items-center justify-content-between"
          :style="
            restrictedData
              ? 'box-shadow: none !important; border-radius: calc(0.25rem - 1px);'
              : ''
          "
          @click="restrictedData = !restrictedData"
        >
          <span>
            <CIcon name="cil-lock-locked" class="mr-1" />
            Dados restritos
          </span>

          <CIcon
            name="cil-chevron-circle-down-alt"
            class="chevron-arrow"
            :class="{ 'chevron-arrow-up': !restrictedData }"
          />
        </CCardHeader>
        <CCollapse :show="restrictedData">
          <CCardBody>
            <CRow>
              <template v-for="field in lead.restrictedData">
                <CCol
                  col="12"
                  :md="field.cols"
                  :key="field.label"
                  v-if="field.show(item)"
                >
                  <p class="mb-1">
                    <b>{{ field.label }}: </b>
                    {{ field.format(item, field.field) }}
                  </p>
                </CCol>
              </template>
            </CRow>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Modal from '@/components/ui/Modal'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import FormCardTitle from '@/components/ui/Form/FormCardTitle'

import Service from '@/services/leads.service'
import { sleep } from '@/utils'
import leads from './leads'

const defaultItem = () => ({ unity: {}, model: {} })

export default {
  metaInfo: {
    title: 'Leads',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: { Modal, FormCardItem, FormCardTitle },

  data: () => ({
    item: defaultItem(),
    service: null,
    restrictedData: false,
    modal: false,
    removing: false,
    alertModal: {
      show: false,
      title: '',
      message: '',
      color: ''
    }
  }),

  async created() {
    this.service = new Service(this.lead.uri)
    this.setItem()
  },

  computed: {
    cardTitle() {
      const id = this.item.id ? ` #${this.item.id}` : ''
      return `Lead - ${this.$route.meta.name}${id}`
    },
    lead() {
      const type = this.$route.meta.type
      return leads[type]
    },
    fields() {
      return this.lead.fields
    }
  },

  methods: {
    async setItem() {
      const { id } = this.$route.params

      if (!id) this.$router.go(-1)
      const data = await this.service.show(id)

      this.item = { ...this.item, ...data }
    },
    async remove() {
      this.removing = true
      const redirect = `/leads/${this.$route.meta.type}`
      let modalData = {
        show: true,
        title: 'Sucesso',
        message: 'Lead excluído com sucesso.',
        color: 'success',
        redirect: ''
      }

      try {
        await this.service.delete(this.item)
        modalData = { ...modalData, redirect }
      } catch {
        modalData = {
          ...modalData,
          title: 'Erro',
          message: 'Ocorreu um erro ao excluir lead.',
          color: 'danger',
          redirect: ''
        }
      } finally {
        this.removing = false
        this.modal = false

        await sleep(200)
        this.alertModal = modalData
        await sleep(3000)

        modalData.redirect
          ? this.$router.push({ path: redirect })
          : (this.alertModal.show = false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-restricted-data {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: #e0e0e0;

  .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']):not([class*='dropdown-toggle']):not([class*='search-component-button']) {
    box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14) !important;
  }

  .card-header {
    border: 0px;
    padding: 0.75rem 1.25rem !important;
    background-color: rgba(255, 255, 255, 0.3);

    .chevron-arrow {
      transition: 700ms ease;

      &.chevron-arrow-up {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
